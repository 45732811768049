import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { canActivate } from '@core/guard/auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    canActivate: [(next: ActivatedRouteSnapshot) => canActivate(next)],
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
    data: { name: 'home' },
  },
  {
    path: '',
    loadComponent: () =>
      import('./core/layout/layout.component').then((m) => m.LayoutComponent),
    resolve: {
      // appSettings: ,
    },
    canActivateChild: [(next: ActivatedRouteSnapshot) => canActivate(next)],
    data: { breadcrumb: 'Home' },
    children: [
      {
        path: 'current',
        children: [
          {
            path: 'basic',
            loadComponent: () =>
              import('./pages/current-member/list/list.component').then(
                (m) => m.ListComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
          {
            path: 'report',
            loadComponent: () =>
              import('./pages/current-member/report/report.component').then(
                (m) => m.ReportComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
        ],
      },
      {
        path: 'new',
        children: [
          {
            path: 'basic',
            loadComponent: () =>
              import('./pages/new-member/list/list.component').then(
                (m) => m.ListComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
          {
            path: 'report',
            loadComponent: () =>
              import('./pages/new-member/report/report.component').then(
                (m) => m.ReportComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
          {
            path: 'affiliation',
            loadComponent: () =>
              import(
                './pages/new-member/affiliation-report/affiliation-report.component'
              ).then((m) => m.AffiliationReportComponent),
            data: { roles: ['Admin', 'SAdmin', 'Accountant'] },
          },
        ],
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: 'pay',
            loadComponent: () =>
              import('./pages/subscription-member/list/list.component').then(
                (m) => m.ListComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
          {
            path: 'affiliation',
            loadComponent: () =>
              import(
                './pages/subscription-member/affiliation-report/affiliation-report.component'
              ).then((m) => m.AffiliationReportComponent),
            data: { roles: ['Admin', 'SAdmin', 'Accountant'] },
          },
          {
            path: 'delete-unpaid',
            loadComponent: () =>
              import(
                './pages/subscription-member/delete-unpaid-report/report.component'
              ).then((m) => m.ReportComponent),
            data: { roles: ['Admin', 'SAdmin'] },
          },
        ],
      },
      {
        path: 'deleted',
        children: [
          {
            path: 'basic',
            loadComponent: () =>
              import('./pages/deleted-member/list/list.component').then(
                (m) => m.ListComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
          {
            path: 'report',
            loadComponent: () =>
              import('./pages/deleted-member/report/report.component').then(
                (m) => m.ReportComponent
              ),
            data: { roles: ['Admin', 'SAdmin'] },
          },
        ],
      },
      {
        path: 'payment-statistics',
        data: { roles: ['Admin', 'SAdmin', 'Accountant'] },
        children: [
          {
            path: 'coordinator',
            loadComponent: () =>
              import(
                './pages/payment-statistics/coordinators/report.component'
              ).then((m) => m.ReportComponent),
          },
          {
            path: 'individual',
            loadComponent: () =>
              import(
                './pages/payment-statistics/individual/report.component'
              ).then((m) => m.ReportComponent),
          },
          {
            path: 'subscriptionInvoices',
            loadComponent: () =>
              import(
                './pages/payment-statistics/subscriptionInvoices/report.component'
              ).then((m) => m.ReportComponent),
          },
        ],
      },
      {
        path: 'general-assembly',
        data: { roles: ['Admin', 'SAdmin'] },
        children: [
          {
            path: 'allow-attendance',
            loadComponent: () =>
              import(
                './pages/general-assembly/allow-attendance/report.component'
              ).then((m) => m.ReportComponent),
          },
          {
            path: 'not-allow-attendance',
            loadComponent: () =>
              import(
                './pages/general-assembly/not-allow-attendance/report.component'
              ).then((m) => m.ReportComponent),
          },
          {
            path: 'committee',
            loadComponent: () =>
              import(
                './pages/general-assembly/committee/committee.component'
              ).then((m) => m.CommitteeComponent),
          },
        ],
      },
      {
        path: 'mail',
        data: { roles: ['Admin', 'SAdmin'] },
        children: [
          {
            path: 'attendance',
            loadComponent: () =>
              import('./pages/mail/attendance/report.component').then(
                (m) => m.ReportComponent
              ),
          },
          {
            path: 'delete-unpaid',
            loadComponent: () =>
              import('./pages/mail/delete-unpaid-report/report.component').then(
                (m) => m.ReportComponent
              ),
          },
          {
            path: 'pay',
            loadComponent: () =>
              import('./pages/mail/notify-unpaid-report/report.component').then(
                (m) => m.ReportComponent
              ),
          },
        ],
      },
      {
        path: 'address',
        data: { roles: ['Admin', 'SAdmin'] },
        children: [
          {
            path: 'attendance',
            loadComponent: () =>
              import('./pages/address-member/attendance/report.component').then(
                (m) => m.ReportComponent
              ),
          },
          {
            path: 'delete-unpaid',
            loadComponent: () =>
              import(
                './pages/address-member/delete-unpaid-report/report.component'
              ).then((m) => m.ReportComponent),
          },
          {
            path: 'pay',
            loadComponent: () =>
              import(
                './pages/address-member/notify-unpaid-report/report.component'
              ).then((m) => m.ReportComponent),
          },
        ],
      },
      {
        path: 'other',
        data: { roles: ['Admin', 'SAdmin'] },
        children: [
          {
            path: 'profession',
            loadComponent: () =>
              import('./pages/others/profession/profession.component').then(
                (m) => m.ProfessionComponent
              ),
          },
          {
            path: 'coordinator',
            loadComponent: () =>
              import('./pages/others/coordinator/coordinator.component').then(
                (m) => m.CoordinatorComponent
              ),
          },
          {
            path: 'area',
            loadComponent: () =>
              import('./pages/others/area/area.component').then(
                (m) => m.AreaComponent
              ),
          },
          {
            path: 'social-status',
            loadComponent: () =>
              import(
                './pages/others/social-status/social-status.component'
              ).then((m) => m.SocialStatusComponent),
          },
          {
            path: 'qualification',
            loadComponent: () =>
              import(
                './pages/others/qualification/qualification.component'
              ).then((m) => m.QualificationComponent),
          },
          {
            path: 'setting',
            loadComponent: () =>
              import('./pages/others/setting/setting.component').then(
                (m) => m.SettingComponent
              ),
          },
          {
            path: 'reject-reason',
            loadComponent: () =>
              import(
                './pages/others/reject-reasons/reject-reasons.component'
              ).then((m) => m.RejectReasonsComponent),
          },
          {
            path: 'users-list',
            loadComponent: () =>
              import('./pages/others/users-list/users-list.component').then(
                (m) => m.UsersListComponent
              ),
          },
        ],
      },
    ],
  },
];
