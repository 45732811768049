import { EventEmitter, inject, Injectable } from '@angular/core';
import { BehaviorSubject, noop, Observable, skip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { environment } from '@environments/environment';
import { MenuSideBarService } from '@app/core/services/menu-sidebar.service';
import { DateAdapter } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import ar from '@angular/common/locales/ar';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  dateAdapter = inject(DateAdapter<any>);
  translateService = inject(TranslateService);
  generalService = inject(MenuSideBarService);
  http = inject(HttpClient);
  private defaultLang = environment.DEFAULT_LANGUAGE;
  private _langSubject = new BehaviorSubject<string>(this.defaultLang);

  public lang$ = this._langSubject.pipe(skip(1));
  private _translationsUrl = 'assets/i18n';
  private _languageList: string[] = ['en', 'ar'];
  dir: 'rtl' | 'ltr' = 'rtl';
  private _dir = new BehaviorSubject<'rtl' | 'ltr'>(this.dir);

  get dir$(): Observable<'ltr' | 'rtl'> {
    return this._dir.asObservable();
  }

  public currentLanguage() {
    const _language = localStorage.getItem('_language');
    if (!_language || !this._languageList.includes(_language)) {
      this._setCurrentLanguage(this.defaultLang);
      return this.defaultLang;
    }
    return _language;
  }

  private _setCurrentLanguage(language: string, initialLoad?: boolean) {
    localStorage.setItem('_language', language);
    this.dir = this.currentLanguage() === 'ar' ? 'rtl' : 'ltr';
    this._dir.next(this.dir);
    this.generalService.changeTheme(language);
    this.dateAdapter.setLocale(language);
    registerLocaleData(ar);
    initialLoad ? noop() : this._langSubject.next(language);
  }

  public use(language: string, initialLoad?: boolean) {
    this._setCurrentLanguage(language, initialLoad);
    return this.translateService.use(language);
  }

  public setDefaultLang(language: string) {
    return this.translateService.setDefaultLang(language);
  }

  public reloadLang(language: string) {
    return this.translateService.reloadLang(language);
  }

  public resetLang(language: string) {
    return this.translateService.resetLang(language);
  }

  public onSetupMultiLanguage(prefixName: string) {
    this.lang$.subscribe((value) => {
      const language = value;
      this.translateService.use(language).subscribe(() => {
        this.loadTranslations(language, prefixName);
      });
    });

    this.translateService.use(this.currentLanguage()).subscribe(() => {
      this.loadTranslations(this.currentLanguage(), prefixName);
    });
  }

  private loadTranslations(locale: string, prefixName: string) {
    let cacheBuster = new Date().toISOString().replace(/\\.|:|-/g, '');
    return this.http
      .get(
        `${this._translationsUrl}/${prefixName}/${locale}.json?cacheBuster=${cacheBuster}`
      )
      .subscribe((data: any) => {
        this.translateService.setTranslation(locale, data, true);
      });
  }

  public get(
    key: string | Array<string>,
    interpolateParams?: Object
  ): Observable<string | any> {
    return this.translateService.get(key, interpolateParams);
  }

  public instant(key: string | Array<string>, interpolateParams?: Object) {
    return this.translateService.instant(key, interpolateParams);
  }
  public stream(key: string | Array<string>, interpolateParams?: Object) {
    return this.translateService.stream(key, interpolateParams);
  }

  get onLangChange(): EventEmitter<LangChangeEvent> {
    return new this.translateService.onLangChange();
  }
}
