import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as fromInterceptors from '@core/interceptors';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { LanguageService } from '@core/translate';
import {
  appInitializerLanguageFactory,
  loadProfile,
} from '@app/core/interceptors/appInitializerFactory';
import { CustomPreloadingStrategy } from '@core/common/providers/custom-preloading-strategy';
import { AuthenticateService } from '@core/services/authenticate/authenticate.service';

export const providers = [
  LanguageService,
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerLanguageFactory,
    deps: [LanguageService, Injector],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: loadProfile,
    deps: [AuthenticateService],
    multi: true,
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: fromInterceptors.HandleErrorsInterceptor,
  //   multi: true,
  // },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: fromInterceptors.TimingInterceptor,
  //   multi: true,
  // },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: fromInterceptors.LoadingInterceptor,
  //   multi: true,
  // },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: fromInterceptors.TimeoutInterceptor,
  //   multi: true,
  // },
  // { provide: DEFAULT_TIMEOUT, useValue: config.DEFAULT_TIMEOUT },
  // { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  // { provide: RouteReuseStrategy, useClass: RouteReusableStrategy },
  CustomPreloadingStrategy,
];
