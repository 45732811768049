import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthenticateService } from '@core/services/authenticate/authenticate.service';
import { Observable } from 'rxjs';

export function canActivate(
  route: ActivatedRouteSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree {
  const authenticateService = inject(AuthenticateService);
  const router = inject(Router);
  if (authenticateService.getAuthStatus()) {
    const userRole = authenticateService.getUserRole();
    if (route.data.roles && route.data.roles.indexOf(userRole) === -1) {
      router.navigate(['/login']).then();
      return false;
    }
    //console.log("Auth Done " + userRole);
    //console.log("Auth Done " + route.data.roles.indexOf(userRole));
    return true;
  }

  router.navigate(['/login']).then();
  return true;
}
