import { inject, Injector } from "@angular/core";
import { LOCATION_INITIALIZED } from '@angular/common';
import { LanguageService } from '@core/translate';
import User from '@core/model/user.model';
import { Observable, of } from 'rxjs';
import { KazmaRes } from '@core/model';
import { AuthenticateService } from '@core/services/authenticate/authenticate.service';
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

export function appInitializerLanguageFactory(
  translate: LanguageService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const langToSet = translate.currentLanguage();
        translate.use(langToSet, true).subscribe({
          next: () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          error: () => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          complete: () => {
            resolve(null);
          },
        });
      });
    });
}
export function loadProfile(authenticateService: AuthenticateService) {
  return (): Observable<KazmaRes<User>> => {
      if (authenticateService.getAuthStatus()) {
        return authenticateService.getUserProfile();
      } else {
        return of(null);
      }

  };
}
