import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { KazmaRes, LookupModel, LookupReq } from '@core/model';
import { take, tap } from 'rxjs/operators';
import User from '@core/model/user.model';
import { Router } from '@angular/router';
import { Link } from '@app/pages/home/home.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  baseUrl = `${environment.baseUrl}Authenticate/`;
  http = inject(HttpClient);
  router = inject(Router);
  user$ = new BehaviorSubject<User>(null);

  login(body: {
    username: string;
    password: string;
  }): Observable<KazmaRes<any>> {
    return this.http.post<KazmaRes<User>>(this.baseUrl + 'Login', body).pipe(
      take(1),
      tap((res) => {
        res.data.fullName = `${res.data.firstName} ${res.data.lastName}`;
        this.user$.next(res.data);
      })
    );
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  getAuthStatus(): boolean {
    return !!localStorage.getItem('token');
  }
  getUserRole = () => {
    return this.user$.getValue()?.roleName;
  };
  haveAccess = (link: Link) => {
    if (!link.roles) {
      return true;
    }
    return link.roles && link.roles.indexOf(this.getUserRole()) !== -1;
  };
  getUsers(
    body: LookupReq = { Page: 1, Size: 1000 }
  ): Observable<KazmaRes<User[]>> {
    return this.http
      .get<KazmaRes<User[]>>(this.baseUrl + 'GetUsers', {
        params: body as any,
      })
      .pipe(take(1));
  }
  getUserProfile(): Observable<KazmaRes<User>> {
    return this.http.get<KazmaRes<User>>(this.baseUrl + 'GetUserProfile').pipe(
      take(1),
      tap((res) => {

        if (res.result.statusCode == 1) {
          res.data.fullName = `${res.data.firstName} ${res.data.lastName}`;
          this.user$.next(res.data);
        }

      })
    );
  }
  getRoles(
    body: LookupReq = { Page: 1, Size: 1000 }
  ): Observable<KazmaRes<LookupModel[]>> {
    return this.http
      .get<KazmaRes<LookupModel[]>>(this.baseUrl + 'GetRoles', {
        params: body as any,
      })
      .pipe(take(1));
  }
  updateUser(
    body: User,
    action: 'add' | 'edit' | 'delete'
  ): Observable<KazmaRes<User[]>> {
    body.Page = 1;
    body.Size = 20;
    switch (action) {
      case 'add':
        return this.http
          .post<KazmaRes<User[]>>(this.baseUrl + 'Register', body)
          .pipe(take(1));
      case 'edit':
        return this.http
          .post<KazmaRes<User[]>>(this.baseUrl + 'UpdateUser', body)
          .pipe(take(1));
      case 'delete':
        return this.http
          .delete<KazmaRes<User[]>>(this.baseUrl + 'DeleteUser', {
            params: body as any,
          })
          .pipe(take(1));
    }
  }
}
