import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuSideBarService {
  private openSideMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(@Inject(DOCUMENT) private document: Document) {}

  get sideMenuStatus() {
    return this.openSideMenu$;
  }
  openSideMenu(): void {
    this.toggleSideMenuOpen();
    this.openSideMenu$.next(true);
  }
  closeSideMenu(): void {
    this.toggleSideMenuClose();
    this.openSideMenu$.next(false);
  }
  private toggleSideMenu(): void {
    this.document.body.classList.toggle('mobile-nav-on');
  }
  private toggleSideMenuOpen(): void {
    this.document.body.classList.add('mobile-nav-on');
  }
  private toggleSideMenuClose(): void {
    this.document.body.classList.remove('mobile-nav-on');
  }

  addUpButtonLisner(): void {
    let btnUp = document.getElementById('up_btn');
    if (btnUp !== undefined && btnUp !== null) {
      window.addEventListener('scroll', () => {
        if (btnUp) {
          if (window.scrollY >= 600) {
            btnUp.classList.add('fade');
          } else {
            btnUp.classList.remove('fade');
          }
        }
      });
      btnUp.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    }
  }
  changeTheme(lang: string) {
    if (lang === 'ar') {
      this.document.body.classList.add('rtl');
    } else {
      this.document.body.classList.remove('rtl');
    }
  }
}
