export const environment = {
  production: true,
  //baseUrl: 'http://kazmaapidev.softlinktest.com/api/',
  //baseUrl: 'http://192.168.0.100:5000/api/',
  baseUrl: 'https://kazmaapi.softlinkkw.com/api/',

  DEFAULT_LANGUAGE: 'ar',
  DEFAULT_TIMEOUT: 10000,
  TOAST_DURATION: 3000,
  PROJECT_NAME: 'kazma Member',
  FESS_AMOUNT: 10,
};
